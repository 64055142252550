// components/Content.js
import React from "react";
import AcquisitionFlag from "./AcquisitionFlag";

const Content = () => {
  return (
    <div className="content">
      <div className="text-container">
        <AcquisitionFlag />
        <h1>Kairos</h1>
        <p>
          The modern workspace is broken. Endless notifications, cluttered
          schedules, and priority mismanagement. We spend our days juggling
          twenty tools to get a single task done, watching our focus die with
          every context switch.
        </p>

        <p>
          Instead of building another app to add to the noise, we're studying
          how people actually think and work. Our goal is simple: software that
          understands the connections between your work and helps without
          interrupting.
        </p>

        <p>
          No grand promises about AI. No buzzwords about the future of
          productivity. Just a team obsessed with making computers work the way
          our brains do.
        </p>
      </div>
    </div>
  );
};

export default Content;
