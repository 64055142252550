// components/FloatingSphere.js
import React from "react";

const FloatingSphere = () => {
  return (
    <div className="sphere-container">
      <div className="sphere"></div>
    </div>
  );
};

export default FloatingSphere;
